import {createAction, props} from "@ngrx/store";
import { NewsModel } from "../../../models/interfaces/news-model";
import { NewsMessageDetailsModel } from "../../../models/interfaces/news-message-details.model";
import { TeacherMessageDetailsModel } from "../../../models/interfaces/teacher-message.model";

export const initNews = createAction('[News] Init All News');

export const setNews = createAction('[News] Set News', props<{ newsData: NewsModel[] }>());

export const loadRecentNews = createAction('[News] Load Recent News');

export const setTeacherMessages = createAction('[News] Set Teacher Messages', props<{ messages: TeacherMessageDetailsModel[] }>());

export const getRecentNewsDetails = createAction('[News] Get Recent News Details');

export const setRecentNewsDetails = createAction('[News] Set Recent News Details', props<{ newsData: NewsMessageDetailsModel }>());
