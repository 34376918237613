import {createAction, props} from "@ngrx/store";
import {UserModel} from "../../../models/interfaces/user-model";
import {DictionaryModel} from "../../../models/interfaces/dictionary-model";
import {NotificationModel} from "../../../models/interfaces/notification-model";

export const initAuthUserData = createAction('[User] Init Auth User Data', props<{ user: UserModel }>());

export const initUser = createAction('[User] Init User', props<{ token: string }>());

export const setLoading = createAction('[User] Set Loading', props<{ isLoading: boolean }>());

export const setLoggedOut = createAction('[User] Set Logged Out');

export const refreshUser = createAction('[User] Refresh User');

export const updateUser = createAction('[User] Update User', props<{ user: UserModel }>());

export const setDictionary = createAction('[User] Set Dictionary', props<{ dictionary: DictionaryModel }>());

export const getAnnouncement = createAction('[User] Get Announcement');

export const setNotification = createAction('[User] Set Notification', props<{ notification: NotificationModel }>());

export const removeNotification = createAction('[User] Remove Notification', props<{ notification: NotificationModel }>());
