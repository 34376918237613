import {createReducer, on} from "@ngrx/store";
import {UserActions} from "../actions";
import {UserModel} from "../../../models/interfaces/user-model";
import {DictionaryModel} from "../../../models/interfaces/dictionary-model";
import {NotificationModel} from "../../../models/interfaces/notification-model";

export const userFeatureKey = 'user';

export interface UserState {
    user: UserModel | null;
    isLoading: boolean;
    isLoggedOut: boolean;
    dictionary: DictionaryModel | null;
    notifications: NotificationModel[];
}

export const initialState: UserState = {
    user: null,
    isLoading: false,
    isLoggedOut: false,
    dictionary: null,
    notifications: [],
}

export const userReducer = createReducer(
    initialState,
    on(UserActions.initAuthUserData, (state, {user}) => ({
        ...state,
        user,
        isLoggedOut: false,
    })),
    on(UserActions.updateUser, (state, {user}) => ({
        ...state,
        user,
    })),
    on(UserActions.setLoggedOut, () => ({
        ...initialState,
        isLoggedOut: true,
    })),
    on(UserActions.setLoading, (state, {isLoading}) => ({
        ...state,
        isLoading: isLoading,
    })),
    on(UserActions.setDictionary, (state, {dictionary}) => ({
        ...state,
        dictionary,
    })),
    on(UserActions.setNotification, (state, {notification}) => ({
        ...state,
        notifications: [notification, ...state.notifications],
    })),
    on(UserActions.removeNotification, (state, {notification}) => ({
        ...state,
        notifications: state.notifications.filter(n => n.getId() !== notification.getId()),
    })),
)
