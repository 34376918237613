import {createAction, props} from "@ngrx/store";
import { CalendarEventModel, EventModel, EventsPagingMetaData } from "../../../models/interfaces/events-model";

export const initEventsLoadingWithPaginationFromDate = createAction('[Events] Init Events Loading With Pagination From Date', props<{ dateFrom: Date }>());

export const initSidebarCalendarEvents = createAction('[Events] Init Sidebar Calendar Events');

export const setEvents = createAction('[Events] Set Events', props<{ eventsData: EventModel[] | null }>());

export const setEventsPagingMetadata = createAction('[Events] Set Events Paging MetaData', props<{ metaData: EventsPagingMetaData | null }>());

export const resetEventsPagingMetadata = createAction('[Events] Reset Events Paging MetaData');

export const resetEvents = createAction('[Events] Reset Events');

export const loadSidebarCalendarEventsDataChunk = createAction('[Events] Load Sidebar Calendar Events Data Chunk', props<{ dateFrom: Date, dateTo: Date }>());

export const loadCalendarEventsDataChunk = createAction('[Events] Load Calendar Events Data Chunk', props<{ dateFrom: Date, dateTo: Date }>());

export const addCalendarEvent = createAction('[Events] Add Calendar Event to Store', props<{ eventsData: CalendarEventModel[] }>());

export const calendarEventsDataLoaded = createAction('[Events] Calendar Event Loaded ');

export const addSidebarCalendarEvent = createAction('[Events] Add Sidebar Calendar Event to Store', props<{ eventsData: CalendarEventModel[] }>());

export const loadAndOverrideAllEventsData = createAction('[Events] Load And Override All Events Data', props<{ dateFrom: Date, dateTo: Date, isPaginated: boolean }>());

export const loadAndAddEventsDataChunk = createAction('[Events] Load And Add Events Data Chunk', props<{ dateFrom: Date, dateTo?: Date, page?: number }>());
