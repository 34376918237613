import {createReducer, on} from "@ngrx/store";
import { NewsActions } from "../actions";
import { NewsModel } from "../../../models/interfaces/news-model";
import { NewsMessageDetailsModel } from "../../../models/interfaces/news-message-details.model";
import { TeacherMessageDetailsModel } from "../../../models/interfaces/teacher-message.model";

export const newsFeatureKey = 'news';

export interface NewsState {
    newsList: NewsModel[] | null;
    teacherMessages: TeacherMessageDetailsModel[] | null;
    recentNewsDetails: NewsMessageDetailsModel | null
}

export const initialState: NewsState = {
    newsList: null,
    teacherMessages: null,
    recentNewsDetails: null,
}

export const newsReducer = createReducer(
    initialState,
    on(NewsActions.setNews, (state, { newsData }) => ({
        ...state,
        newsList: [ ...newsData ],
    })),
    on(NewsActions.setTeacherMessages, (state, { messages }) => ({
        ...state,
        teacherMessages: [ ...messages ],
    })),
    on(NewsActions.setRecentNewsDetails, (state, { newsData }) => ({
        ...state,
        recentNewsDetails: newsData,
    })),
)
