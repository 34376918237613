import {createReducer, on} from "@ngrx/store";
import {EventsActions} from "../actions";
import { CalendarEventModel, EventModel, EventsPagingMetaData } from "../../../models/interfaces/events-model";

export const eventsFeatureKey = 'events';

export interface EventsState {
    calendarEventsList: CalendarEventModel[] | null;
    sidebarCalendarEventsList: CalendarEventModel[] | null;
    eventsList: EventModel[] | null;
    eventsPagingMetaData: EventsPagingMetaData | null;
}

export const initialState: EventsState = {
    calendarEventsList: null,
    sidebarCalendarEventsList: null,
    eventsList: null,
    eventsPagingMetaData: null
}

export const eventsReducer = createReducer(
    initialState,
    on(EventsActions.setEvents, (state, { eventsData }) => ({
        ...state,
        eventsList:  [...eventsData! ],
    })),
    on(EventsActions.resetEvents, (state) => ({
        ...state,
        eventsList:  null,
    })),
    on(EventsActions.setEventsPagingMetadata, (state, { metaData }) => ({
        ...state,
        eventsPagingMetaData: metaData,
    })),
    on(EventsActions.resetEventsPagingMetadata, (state) => ({
        ...state,
        eventsPagingMetaData:  null,
    })),
    on(EventsActions.addSidebarCalendarEvent, (state, { eventsData }) => ({
        ...state,
        sidebarCalendarEventsList: [
          ...(state.sidebarCalendarEventsList ?? []),
          ...eventsData.filter(event =>
            !(state.sidebarCalendarEventsList ?? []).some(existingEvent =>
              existingEvent.dateFrom === event.dateFrom &&
              existingEvent.dateTo! === event.dateTo! &&
              existingEvent.actionType === event.actionType
            )
          )
        ]
      })),
      on(EventsActions.addCalendarEvent, (state, { eventsData }) => ({
          ...state,
          calendarEventsList: [
            ...(state.calendarEventsList ?? []),
            ...eventsData.filter(event =>
              !(state.calendarEventsList ?? []).some(existingEvent =>
                existingEvent.dateFrom === event.dateFrom &&
                existingEvent.dateTo! === event.dateTo! &&
                existingEvent.actionType === event.actionType
              )
            )
          ],
        }))
)
